import React, { useState, useEffect } from "react";
import "./index.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "../Footer";
import { Icon } from "@iconify/react";

// import material ui
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//import images
import decathlonLogo from "../../img/new_logo_decathlon.png";
// import images
import screenshotOneshopSportpage from "../../img/oneshop-sportpage-screenshot.png";
import screenshotTwoCategories from "../../img/two-categories-screenshot.png";
import screenshotThreeCategories from "../../img/three-categories-screenshot.png";
import screenshotFourCategories from "../../img/four-categories-screenshot.png";
import screenshotArticle1 from "../../img/article1-screenshot.png";
import screenshotBanner1 from "../../img/banner1-screenshot.png";
import screenshotVideo from "../../img/video-section.png";
import screenshotTrboLoading from "../../img/trbo-loading.png";
import screenshotProductsSlider2 from "../../img/products-slider2-screenshot.png";
import loginImgSportPageKajak from "../../img/sport-page-canva.png";
import loginImgHpBanner from "../../img/login-page-img-hp-banner.png";
import loginImgSlider2 from "../../img/login-page-img-slider2.png";
import loginImgCampaignPage from "../../img/login-img-campaign-page.png";
import loginImgContentPage from "../../img/login-img-content-page.png";
import loginImgOverrideCssJs from "../../img/screenshot-override-css-js.png";
import loginImgNewIdeas from "../../img/login-page-img-new-ideas.jpg";
import axios from "axios";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const [
    displayIndexNumOfDynamicTextData,
    setDisplayIndexNumOfDynamicTextData,
  ] = useState(0);

  const [displayDynamicText, setDisplayDynamicText] = useState([
    {
      id: 1,
      text: "Local features",
      //img: loginImgSportPageKajak,
    },
    {
      id: 2,
      text: "UI UX",
      //img: loginImgHpBanner,
    },
    {
      id: 3,
      text: "web development",
      // img: loginImgCampaignPage,
    },
    {
      id: 4,
      text: "AB testing",
      //img: loginImgContentPage,
    },
    {
      id: 5,
      text: "New ideas",
      // img: loginImgOverrideCssJs,
    },
  ]);

  function next() {
    if (displayIndexNumOfDynamicTextData < 4) {
      setDisplayIndexNumOfDynamicTextData((prev) => prev + 1);
    } else {
      setDisplayIndexNumOfDynamicTextData(0);
    }
  }

  function prev() {
    if (displayIndexNumOfDynamicTextData > 0) {
      setDisplayIndexNumOfDynamicTextData((prev) => prev - 1);
    }
  }

  useEffect(() => {
    setTimeout(next, 2000);
  }, [displayIndexNumOfDynamicTextData]);

  console.log(
    "displayIndexNumOfDynamicTextData ",
    displayIndexNumOfDynamicTextData
  );

  return (
    <>
      <div className="login">
        <div className="login-content">
          <div className="login__left">
            <div className="login__logo">
              <img src={decathlonLogo} />
            </div>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className="title onefront-login-title"
              style={{ color: "black" }}
            >
              onefront
            </Typography>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className="title login-title"
            >
              Oneshop countries collaborate on
            </Typography>
            <div id="login-dynamic-text-container">
              <TransitionGroup>
                <CSSTransition
                  key={
                    displayDynamicText[displayIndexNumOfDynamicTextData] &&
                    displayDynamicText[displayIndexNumOfDynamicTextData].id
                  }
                  timeout={300}
                  classNames="item"
                >
                  <Typography
                    variant="h3"
                    component="h2"
                    gutterBottom
                    className="title login-title"
                    id="login-dynamic-text"
                  >
                    {displayDynamicText[displayIndexNumOfDynamicTextData] &&
                      displayDynamicText[displayIndexNumOfDynamicTextData].text}
                  </Typography>
                </CSSTransition>
              </TransitionGroup>
            </div>

            {/*     
           <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className="title login-title"
            >
              which can be used for<br></br> OneShop
            </Typography>
                
                */}

            <div className="login-btn-container">
              <Button
                variant="contained"
                className="login-btn"
                onClick={() => loginWithRedirect()}
              >
                <span>Login / signup</span> <i class="fas fa-arrow-right"></i>
              </Button>
            </div>
            <p className="login__decathlon-email-message"></p>
            {/*
                        <div>
              <Icon
                icon="twemoji:flag-austria"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-slovakia"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-sweden"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-croatia"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-slovenia"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-greece"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-ireland"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-bulgaria"
                style={{ marginRight: "10px" }}
              />
              <Icon
                icon="twemoji:flag-israel"
                style={{ marginRight: "10px" }}
              />
              <Icon icon="twemoji:flag-chile" style={{ marginRight: "10px" }} />
            </div>
            */}
          </div>

          <div className="login__right">
            <div className="login__right-img-container">
              <TransitionGroup>
                <CSSTransition
                  key={
                    displayDynamicText[displayIndexNumOfDynamicTextData] &&
                    displayDynamicText[displayIndexNumOfDynamicTextData].id
                  }
                  timeout={300}
                  classNames="item"
                >
                  <img
                    src={
                      displayDynamicText[displayIndexNumOfDynamicTextData] &&
                      displayDynamicText[displayIndexNumOfDynamicTextData].img
                    }
                  />
                </CSSTransition>
              </TransitionGroup>
            </div>

            {/*
                    <div className="login__right-img-container">
                <TransitionGroup>
                            <CSSTransition
                            key={displayDynamicText[displayIndexNumOfDynamicTextData] && displayDynamicText[displayIndexNumOfDynamicTextData].id}
                            timeout={300}
                            classNames="item"
                            >
                            <img src={displayDynamicText[displayIndexNumOfDynamicTextData] && displayDynamicText[displayIndexNumOfDynamicTextData].img} />
                        </CSSTransition>
                        </TransitionGroup>
                </div>
                */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
