import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// import external installations
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-html";
import copy from "copy-to-clipboard";
import { Icon } from "@iconify/react";

// import material ui:
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// import images
import screenshotOneshopSportpage from "../../img/oneshop-sportpage-screenshot.png";
import screenshotTwoCategories from "../../img/two-categories-screenshot.png";
import screenshotThreeCategories from "../../img/three-categories-screenshot.png";
import screenshotFourCategories from "../../img/four-categories-screenshot.png";
import screenshotArticle1 from "../../img/article1-screenshot.png";
import screenshotBanner1 from "../../img/banner1-screenshot.png";
import screenshotVideo from "../../img/video-section.png";
import screenshotProductsSlider2 from "../../img/products-slider2-screenshot.png";
import screenshotProductsSlider1 from "../../img/oneshop-product-slider-1-screenshop.png";
import codeImage from "../../img/sport-page-code-convertor.jpg";
import screenshotProductSliderWithVariant from "../../img/product-slider-with-variants.PNG";
import screenshotAlgoliaProductsSlider from "../../img/algolia-products-slider-screenshot.PNG";
import screenshotShowroom from "../../img/showroom-sport-page-screenshot.PNG";

// import popups components
import PopupSportPage from "../../sportLpPopupComponents/PopupOneshopSportpageV2";
import Popup2Categories from "../../sportLpPopupComponents/Popup2Categories";
import Popup3Categories from "../../sportLpPopupComponents/Popup3Categories";
import Popup4Categories from "../../sportLpPopupComponents/Popup4Categories";
import PopupArticle1 from "../../sportLpPopupComponents/PopupArticle1";
import PopupBanner1 from "../../sportLpPopupComponents/PopupBanner1";
import PopupVideo from "../../sportLpPopupComponents/PopupVideo";
import PopupProductsSlider2 from "../../sportLpPopupComponents/PopupProductsSlider2";
import PopupProductsSlider1 from "../../sportLpPopupComponents/PopupProductsSlider1";
import PopupCodeConvertorSp from "../../sportLpPopupComponents/PopupCodeConvertorSp";
import DeleteFeaturePopup from "../../generalPopups/DeleteFeaturePopup";

const useStylesImgList = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    //backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    //width: 500,
    // height: 450
    width: 600,
    height: 550,
  },
  icon: {
    //color: 'rgba(255, 255, 255, 0.54)',
    color: "#fff",
  },
}));

const SportLPV3 = (props) => {
  const { user } = useAuth0();
  const classesImgList = useStylesImgList();
  const [imgData, setImgData] = useState([
    {
      img: screenshotOneshopSportpage,
      name: "Sport page",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "https://www.decathlon.at/content/57-",
      onclickDoc: () => setOpenSportPageComponent(true),
      onclickExampleLink: () =>
        window.open("https://www.decathlon.at/content/57-", "_blank"),
    },

    {
      img: screenshotAlgoliaProductsSlider,
      name: "Algolia products slider",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () =>
        window.open(
          "https://docs.google.com/document/d/15U4PGgojbtIgXbxkps9CWkUQz_7VTo041dZSMr7wz3c/edit?usp=sharing",
          "_blank"
        ),
      onclickExampleLink: () => window.open("", "_blank"),
    },

    {
      img: screenshotShowroom,
      name: "Showroom",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () =>
        window.open(
          "https://docs.google.com/document/d/15ScL2KEi42K44Sxt15UPiql2vTkP8w3Bqwvpt1EU7tY/edit?usp=sharing",
          "_blank"
        ),
      onclickExampleLink: () => window.open("", "_blank"),
    },

    {
      img: screenshotTwoCategories,
      name: "2 categories",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpen2CategoriesComponent(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },

    {
      img: screenshotThreeCategories,
      name: "3 categories",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpen3CategoriesComponent(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },
    {
      img: screenshotFourCategories,
      name: "4 categories",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpen4CategoriesComponent(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },

    {
      img: screenshotArticle1,
      name: "Article",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpenArticle1Component(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },
    /*
    {
      img: screenshotBanner1,
      name: "Banner",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpenBanner1Component(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },
    */
    {
      img: screenshotVideo,
      name: "Video",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpenVideoComponent(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },
    /*
    {
      img: screenshotProductsSlider2,
      name: "Product advantages slider",
      description: "",
      icon: "twemoji:flag-austria",
      uploadedBy: "hadar.acobas@decathlon.com",
      country: "Austria",
      type: "sport page",
      exampleLink: "",
      onclickDoc: () => setOpenProductsSlider2Component(true),
      onclickExampleLink: () => window.open("", "_blank"),
    },
*/
    /* {
      img: screenshotProductsSlider1,
      title: 'Product slider',
      type: 'page component',
      onclick: () => setOpenProductsSlider1Component(true)
    },*/

    /* {
      img: codeImage,
      title: 'sport page v1 to v2',
      type: 'code converter',
      onclick: () => setOpenSportPageCodeConvertorV1ToV2(true)
    }*/
  ]);

  const [getMainCode, setGetMainCode] = useState("");
  const [mergedFeaturesData, setMergedFeaturesData] = useState([]);
  // GET EXTERNAL FEATURES UPLOADED BY USERS
  useEffect(() => {
    async function mergeExternalFeaturesToMainFeaturesData() {
      const data = await axios.get(
        process.env.REACT_APP_SHEET_BEST_EXTERNAL_FEATURE_API
      );
      const relExternalFeatures = data.data.filter(
        (e) => e.category === "sport page"
      );
      let restructureExternalArrayData = [];
      if (relExternalFeatures.length > 0) {
        for (let i = 0; i < relExternalFeatures.length; i++) {
          restructureExternalArrayData.push({
            featureId: relExternalFeatures[i].featureId,
            img: relExternalFeatures[i].imageUrl,
            name: relExternalFeatures[i].name,
            description: relExternalFeatures[i].description,
            icon: relExternalFeatures[i].icon,
            type: relExternalFeatures[i].type,
            uploadedBy: relExternalFeatures[i].uploadedBy,
            exampleLink: relExternalFeatures[i].exampleLink,
            onclickDoc: () =>
              window.open(relExternalFeatures[i].docUrl, "_blank"),
            onclickExampleLink: () =>
              window.open(relExternalFeatures[i].exampleLink, "_blank"),
          });
          if (i === relExternalFeatures.length - 1) {
            setMergedFeaturesData([
              ...imgData,
              ...restructureExternalArrayData,
            ]);
          }
        }
      } else {
        setMergedFeaturesData(imgData);
      }
    }
    mergeExternalFeaturesToMainFeaturesData();
  }, [imgData]);

  // open/close popup states
  const [openSportPageComponent, setOpenSportPageComponent] = useState(false);
  const [open2CategoriesComponent, setOpen2CategoriesComponent] =
    useState(false);
  const [open3CategoriesComponent, setOpen3CategoriesComponent] =
    useState(false);

  const [open4CategoriesComponent, setOpen4CategoriesComponent] =
    useState(false);

  const [openArticle1Component, setOpenArticle1Component] = useState(false);

  const [openBanner1Component, setOpenBanner1Component] = useState(false);

  const [openVideoComponent, setOpenVideoComponent] = useState(false);

  const [openProductsSlider2Component, setOpenProductsSlider2Component] =
    useState(false);

  const [openProductsSlider1Component, setOpenProductsSlider1Component] =
    useState(false);

  const [
    openSportPageCodeConvertorV1ToV2,
    setOpenSportPageCodeConvertorV1ToV2,
  ] = useState(false);

  // start delete and update states
  const [openDeleteFeature, setOpenDeleteFeature] = useState(false);
  const [featureNameForDelete, setFeatureNameForDelete] = useState("");
  const [featureIdForDelete, setFeatureIdForDelete] = useState("");
  // end delete and update states

  // GENERAL STATES:
  const [codeEditorModeFull, setCodeEditorModeFull] = useState(false);

  // copy to clip func
  const [showSuccessCopied, setShowSuccessCopied] = useState(false);
  const copyToClipFunc = () => {
    setShowSuccessCopied(true);
    copy(getMainCode);

    setTimeout(function () {
      setShowSuccessCopied(false);
    }, 2000);
  };

  useEffect(() => {
    // console.log('getMainCode: ', getMainCode);
  }, [getMainCode]);
  /* console.log(
    "sportl3 userDataObjFromSheet: ",
    props.userDataObjFromSheet
  );*/
  return (
    <div className="sportLPV3 page">
      <Typography variant="h3" component="h2" gutterBottom className="title">
        sport page
      </Typography>

      <div className="sportLPV3__content">
        <div
          className="sportLPV3__components"
          style={codeEditorModeFull ? { width: "0" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            style={
              codeEditorModeFull
                ? { display: "none" }
                : { marginBottom: "10px" }
            }
          >
            {props.userDataObjFromSheet.country === "Austria" ||
            props.userDataObjFromSheet.country === "Germany" ? (
              <div style={{ background: "", padding: "10px" }}>
                <span
                  style={{ color: "gray", display: "block", fontSize: "12px" }}
                >
                  This message is visible only for the web marketers of Austia
                </span>
                <span style={{ fontWeight: "bold" }}>Important –</span> Always
                copy the page code from PrestaShop and paste it into your code
                editor before making any edits or publishing it again. You might
                lose updates I’ve made to the code on my side. Also, ensure that
                the menu icons on your sports page are visible. If they aren’t,
                please contact me as you might not be using the updated version.
                Thanks, Hadar 🙂
              </div>
            ) : (
              ``
            )}

            {/* <p>Available components:</p> */}

            {/*
                    <Button variant="contained">
                       How to use
                    </Button>
                     */}
            <ImageListItem
              key="Subheader"
              cols={2}
              style={{ height: "auto", listStyle: "none" }}
            >
              <ListSubheader component="div">
                <LiveHelpIcon style={{ color: "#0082C3", opacity: "0" }} />
              </ListSubheader>
            </ImageListItem>
          </div>

          <div className={classesImgList.root}>
            <ImageList
              style={{ width: "100%", maxWidth: "1000px" }}
              rowHeight={180}
              className={classesImgList.imageList}
            >
              {mergedFeaturesData.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.img} alt={item.title} />
                  <ImageListItemBar
                    title={
                      <div>
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{ fontSize: "10px", marginRight: "5px" }}
                          >
                            {item.country}
                          </span>{" "}
                          <Icon icon={item.icon} />
                        </div>
                        <div style={{ marginTop: "26px" }}>{item.name}</div>
                      </div>
                    }
                    subtitle={
                      <div>
                        <span
                          style={{
                            marginBottom: "6px",
                            display: "block",
                            overflow: "auto",
                            height: "62px",
                          }}
                        >
                          {item.description}
                        </span>

                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",

                            // border: "1px solid green",
                          }}
                        >
                          {/*
                            <span
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            Uploaded by: {item.uploadedBy}
                          </span>
                          */}

                          <div
                            style={{ marginTop: "10px", marginBottom: "6px" }}
                          >
                            <LaunchIcon
                              style={{ marginRight: "8px", cursor: "pointer" }}
                              onClick={item.onclickDoc}
                            />
                            {item.exampleLink &&
                              item.exampleLink.length > 0 && (
                                <RemoveRedEyeIcon
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={item.onclickExampleLink}
                                />
                              )}

                            {user && user.email === item.uploadedBy ? (
                              <DeleteOutlineIcon
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOpenDeleteFeature(true);
                                  setFeatureIdForDelete(item.featureId);
                                  setFeatureNameForDelete(item.name);
                                }}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>

        <div
          className="sportLPV3__code-editor"
          style={codeEditorModeFull ? { width: "100%" } : {}}
        >
          <div
            className="sportLPV3__content-title"
            style={{ marginBottom: "10px", position: "relative" }}
          >
            <p style={{ display: "flex", alignItems: "center" }}>
              {!codeEditorModeFull ? (
                <ChevronLeftIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setCodeEditorModeFull(!codeEditorModeFull)}
                  style={{ color: "lightgray", cursor: "pointer" }}
                />
              )}
              Code editor{" "}
            </p>

            <Button
              onClick={copyToClipFunc}
              variant="contained"
              className="primary-btn"
            >
              Copy code
            </Button>

            {showSuccessCopied && (
              <div
                style={{
                  margin: "10px 0",
                  position: "absolute",
                  right: "125px",
                }}
              >
                <DoneIcon style={{ color: "green" }} fontSize="small" />
              </div>
            )}
          </div>
          <AceEditor
            mode="html"
            width="100%"
            height="700px"
            theme="monokai"
            fontSize={14}
            onChange={(e) => setGetMainCode(e)}
            className="code-editor"
            style={codeEditorModeFull ? { opacity: "1" } : {}}
          />
        </div>
      </div>

      <div className="sportLPV3__popups">
        <PopupSportPage
          imgUrl={screenshotOneshopSportpage}
          open={openSportPageComponent}
          handleClose={() => setOpenSportPageComponent(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <Popup2Categories
          imgUrl={screenshotTwoCategories}
          open={open2CategoriesComponent}
          handleClose={() => setOpen2CategoriesComponent(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <Popup3Categories
          imgUrl={screenshotThreeCategories}
          open={open3CategoriesComponent}
          handleClose={() => setOpen3CategoriesComponent(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <Popup4Categories
          imgUrl={screenshotFourCategories}
          open={open4CategoriesComponent}
          handleClose={() => setOpen4CategoriesComponent(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupArticle1
          imgUrl={screenshotArticle1}
          open={openArticle1Component}
          handleClose={() => setOpenArticle1Component(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupBanner1
          imgUrl={screenshotBanner1}
          open={openBanner1Component}
          handleClose={() => setOpenBanner1Component(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupVideo
          imgUrl={screenshotVideo}
          open={openVideoComponent}
          handleClose={() => setOpenVideoComponent(false)}
        />
        <PopupProductsSlider2
          imgUrl={screenshotProductsSlider2}
          open={openProductsSlider2Component}
          handleClose={() => setOpenProductsSlider2Component(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />
        <PopupProductsSlider1
          imgUrl={screenshotProductsSlider1}
          open={openProductsSlider1Component}
          handleClose={() => setOpenProductsSlider1Component(false)}
          userDataObjFromSheet={props.userDataObjFromSheet}
        />

        <PopupCodeConvertorSp
          imgUrl={codeImage}
          open={openSportPageCodeConvertorV1ToV2}
          handleClose={() => setOpenSportPageCodeConvertorV1ToV2(false)}
        />
        <DeleteFeaturePopup
          open={openDeleteFeature}
          handleClose={() => setOpenDeleteFeature(false)}
          name={featureNameForDelete}
          featureId={featureIdForDelete}
        />
      </div>
    </div>
  );
};

export default SportLPV3;
